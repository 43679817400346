#main-header {
    font-size: 7vh;
}

#subheader {
    font-size: 4vh;
}

p {
    font-size: 15px;
}

p.large {
    font-size: 20px;
}

#videoconferencing-info {
    margin: 0 auto;
}

#videoconferencing-content {
    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            &:first-child {
                font-weight: bold;
                margin-right: 15px;
            }
            font-size: 15px;
        }
    }
}