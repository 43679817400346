@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.split-view {
    display: grid;
    grid-template-columns: 367fr 593fr; /* golden ratio */
    align-items: center;
    height: 100vh;
    img {
        max-width: 80% !important;
        max-height: 80% !important;
    }
}

code {
    border-radius: 3px;
    padding: 2px 5px;
    font-family: "Monaco", "Menlo", "Roboto Mono", monospace;
    background-color: #EEEEEE;
}

.rails {
    margin: 5vh 30vw;
}

a.unstyled {
    text-decoration: none;
    color: inherit;
}

a.unstyled:hover {
    color: inherit;
    text-decoration: none;
}

a.unstyled:visited {
    color: inherit;
    text-decoration: none;
}

.center-aligned {
    text-align: center;
}