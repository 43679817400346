#flex-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    & > a {
        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: #AAAAAA 1px solid;
            padding: 50px 30px;
            h2 {
                margin: 0;
            }
        }
        &:last-of-type div {
            border-bottom: #AAAAAA 1px solid;
        }
    }
}
