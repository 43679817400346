#main-header {
    font-size: 7vh;
}

#sign-in-panel {
    min-width: 30%;
    padding: 15px 30px 15px 30px;
    #split {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        #inputs {
            margin-right: 20px;
        }
    }
    #footer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 15px;
        p {
            margin: 0;
        }
    }
}
